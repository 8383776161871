var __WEBPACK_NAMESPACE_OBJECT__ = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "page-editor-ui": "static-1.25762",
      "ai-components-ui-library": "static-1.2725",
      "apollo-dlb": "static-3.41",
      "apollo-link-hub-http": "static-2.1728",
      "apollo-stack-hubspot": "static-3.43",
      "atom": "static-1.2435",
      "autolinker": "static-3.11",
      "automation-applications-service-types": "static-1.761",
      "automation-ui-source-apps": "static-1.9544",
      "automation-ui-types": "static-1.10462",
      "backbone": "static-1.6",
      "blueimp-md5": "static-1.8",
      "browser-eslint": "static-2.9",
      "browserslist-config-hubspot": "static-1.133",
      "campaigns-lib": "static-1.17889",
      "chatspot-client-types": "static-1.5766",
      "chatspot-core": "static-1.9786",
      "chatspot-widget-iframe": "static-1.9784",
      "cms-field-types": "static-1.1790",
      "codemirror-dlb": "static-1.46958",
      "collaboration-sidebar": "static-1.45581",
      "commerce-analytics-service-client": "static-1.1053",
      "conditional-properties-service-client": "static-1.1554",
      "content-assistance-lib": "static-1.3895",
      "content-embed-lib": "static-1.3581",
      "content-icons-ui": "static-1.1788",
      "content-management-data-lib": "static-1.2035",
      "ContentComponents": "static-2.60599",
      "ContentData": "static-1.57717",
      "ContentEditorUI": "static-2.65408",
      "ContentUtils": "static-1.57639",
      "copilot-plugins": "static-1.1517",
      "copilot-toolkit": "static-1.1543",
      "crm-links": "static-1.2892",
      "crm-message-bus": "static-1.29790",
      "crm-pipelines-api-client-types": "static-1.1391",
      "cropperjs": "static-1.10",
      "csstype": "static-1.8",
      "cssUtils": "static-1.314",
      "cta-picker": "static-1.26936",
      "customer-data-objects": "static-1.4544",
      "customer-data-objects-ui-components": "static-1.9631",
      "customer-data-properties": "static-1.39447",
      "customer-data-property-utils": "static-1.4787",
      "customer-data-reference-ui-components": "static-1.9733",
      "customer-data-tracking": "static-1.3848",
      "customer-data-ui-utilities": "static-1.6723",
      "data-fetching-client": "static-1.5966",
      "data-token-picker": "static-1.10969",
      "data-token-views": "static-1.10953",
      "diff-match-patch": "static-1.7",
      "editor-components": "static-1.9031",
      "editor-iframe-lib": "static-1.5191",
      "EmailComponents": "static-1.41992",
      "EmailData": "static-1.40665",
      "emoji-mart": "static-3.4",
      "emoji-regex": "static-1.7",
      "enviro": "static-4.260",
      "error-messaging-js": "static-1.2356",
      "ExportDialog": "static-6.6863",
      "external-options-client-types": "static-1.1493",
      "fast-json-stable-stringify": "static-1.4",
      "feature-store-service-types": "static-1.1832",
      "feedback-loader": "static-1.23206",
      "file-manager-components": "static-1.13104",
      "FileManagerCore": "static-1.28605",
      "FileManagerImages": "static-1.26255",
      "FileManagerLib": "static-1.28748",
      "FireAlarmUi": "static-1.2273",
      "floating-ui": "static-1.15",
      "forms-utils-lib": "static-1.7275",
      "FormsCommonComponents": "static-1.75613",
      "foundations-assets": "static-1.1755",
      "foundations-components": "static-1.3158",
      "foundations-theming": "static-1.858",
      "framer-motion": "static-1.25",
      "framework-builder-read-service-client": "static-1.1480",
      "framework-data-schema-quick-fetch": "static-1.3267",
      "framework-data-schema-resolvers": "static-1.3364",
      "frontend-preferences-client": "static-1.4501",
      "getting-started-shared-tasks": "static-1.5909",
      "graphql": "static-1.31",
      "growth-data-modal": "static-1.1948",
      "growth-payments-core": "static-1.16330",
      "head-dlb": "static-1.2040",
      "HeadJS": "static-2.491",
      "history": "static-4.15",
      "hs-lodash": "static-4.9",
      "hs-promise-utils": "static-1.2453",
      "hs-story-utils": "static-1.5769",
      "hs-test-utils": "static-1.4821",
      "hub-http": "static-1.2732",
      "hub-http-contrib": "static-1.533",
      "hub-http-janus": "static-1.557",
      "hub-http-rxjs": "static-1.523",
      "hub-http-shared-msw-handlers": "static-1.4471",
      "hubspot-apollo-client": "static-1.1732",
      "hubspot-dlb": "static-1.1772",
      "hubspot-url-utils": "static-1.1402",
      "hubspotter-http": "static-1.2068",
      "HubStyle": "static-2.8679",
      "HubStyleTokens": "static-2.8369",
      "I18n": "static-7.1250",
      "i18n-data": "static-1.183",
      "icons": "static-2.582",
      "immer": "static-1.33",
      "inbound-db-meta-service-client": "static-1.1247",
      "inbound-db-properties-service-client": "static-1.1512",
      "InboxConnectUtils": "static-4.7668",
      "InpageEditorUI": "static-1.73856",
      "integrations-error-boundary-lib": "static-1.4405",
      "integrations-lib": "static-1.6992",
      "interframe": "static-3.1590",
      "jasmine": "static-4.1379",
      "jasmine-immutable": "static-1.328",
      "jasmine-runner": "static-1.3280",
      "jquery": "static-3.6",
      "laboratory-lib": "static-3.3800",
      "layout-data-lib": "static-1.5533",
      "layout-dnd-components": "static-1.8233",
      "layout-dnd-utils": "static-1.7208",
      "marketing-contacts-lib": "static-1.5606",
      "marketing-email-service-types": "static-1.815",
      "marketing-platform-lib": "static-1.3483",
      "media-bridge-lib": "static-1.13622",
      "merchandise-lib": "static-1.202",
      "messaging-editing-lib": "static-1.33428",
      "messaging-types-lib": "static-1.33162",
      "metrics-js": "static-1.6084",
      "moment-timezone": "static-5.17",
      "msw": "static-1.39",
      "object-builder-ui-client": "static-1.34439",
      "onboarding-tours": "static-1.10732",
      "onboarding-tours-client": "static-1.1555",
      "onboarding-utils": "static-1.2192",
      "outpost": "static-1.1477",
      "PatternValidationJS": "static-1.437",
      "payment-link-components": "static-1.12569",
      "payments-embed-points-lib": "static-1.5295",
      "payments-post-enroll-local-storage-lib": "static-1.2014",
      "permission-components": "static-1.2310",
      "persist-promise": "static-1.808",
      "pictos": "static-7.147",
      "pipeline-validation-client-types": "static-1.1455",
      "PortalIdParser": "static-2.239",
      "products-ui-components": "static-1.30484",
      "property-translator": "static-1.2843",
      "quartz": "static-1.3481",
      "quartz-core": "static-1.2700",
      "quartz-core-utils": "static-1.348",
      "quick-fetch": "static-1.1586",
      "raven": "static-3.2477",
      "react": "static-7.133",
      "react-aria": "static-1.7",
      "react-beautiful-dnd": "static-1.13",
      "react-colorpicker": "static-2.7161",
      "react-dnd": "static-4.38",
      "react-dnd-compat": "static-1.1136",
      "react-dnd-html5-backend": "static-4.5",
      "react-dnd-ipeui-backend": "static-1.429",
      "react-flip-move": "static-1.9",
      "react-immutable-proptypes": "static-2.8",
      "react-immutable-render-mixin": "static-1.9",
      "react-input-autosize": "static-2.17",
      "react-redux": "static-7.16",
      "react-rhumb": "static-1.13149",
      "react-router-dom": "static-5.26",
      "react-select-plus": "static-1.65",
      "react-tinymce": "static-1.45132",
      "react-transition-group": "static-1.7",
      "react-utils": "static-2.2744",
      "react-virtualized": "static-2.62",
      "redux-mock-store": "static-1.8",
      "redux-request-state": "static-1.7383",
      "redux-thunk": "static-2.17",
      "redux-undo": "static-1.10",
      "ReduxMessenger": "static-2.7605",
      "reference-resolvers": "static-1.5247",
      "reference-resolvers-lite": "static-1.4814",
      "reselect": "static-2.16",
      "rich-text-lib": "static-1.14748",
      "rpc-client-utils": "static-1.1643",
      "SafeStorage": "static-1.1543",
      "sales-checkout-service-client": "static-1.1065",
      "salesImages": "static-1.485",
      "sanitize": "static-1.6",
      "sanitize-text": "static-1.5188",
      "sassPrefix": "static-1.116",
      "seamless-immutable": "static-7.6",
      "self-service-api": "static-1.7082",
      "settings-ui-lib": "static-1.9356",
      "SharedI18nStrings": "static-1.159",
      "SharedLegalStrings": "static-1.1182",
      "short-messages-app-service-client": "static-1.1320",
      "simple-menus-core": "static-1.10844",
      "simple-menus-lib": "static-1.12362",
      "sinon": "static-1.9",
      "smart-content-core": "static-1.31807",
      "smart-content-lib": "static-1.32462",
      "social-composer-lib": "static-1.48835",
      "structured-content-lib": "static-1.14377",
      "StyleGuideUI": "static-3.421",
      "superstore": "static-1.1768",
      "tanstack-table": "static-1.10",
      "testing-library": "static-1.109",
      "theme-previewer-lib": "static-1.41276",
      "timezone-utils": "static-2.2448",
      "tinymce": "static-6.5",
      "tinymce-config": "static-2.44259",
      "tinymce-copilot": "static-1.6327",
      "tinymce-data": "static-1.30341",
      "tinymce-plugins": "static-1.47372",
      "tinymce-themes": "static-1.46226",
      "transmute": "static-2.29",
      "ui-active-theme-content-creator-lib": "static-1.25629",
      "ui-addon-avatars": "static-2.6506",
      "ui-addon-draggable": "static-1.4342",
      "ui-addon-emoji-picker": "static-1.6871",
      "ui-addon-form-validation": "static-1.7961",
      "ui-addon-i18n": "static-1.7780",
      "ui-addon-iframeable": "static-1.6046",
      "ui-addon-integrations-directory-panel": "static-2.4864",
      "ui-addon-opt": "static-4.5692",
      "ui-addon-react-router-dom": "static-1.6557",
      "ui-addon-sales-email": "static-1.2243",
      "ui-addon-upgrades": "static-9.13326",
      "ui-addon-video-player": "static-1.5817",
      "ui-asset-management-lib": "static-1.6445",
      "ui-business-units-lib": "static-1.5356",
      "ui-coaching-tips": "static-1.15859",
      "ui-components-test-utils": "static-1.2510",
      "ui-fonts": "static-1.326",
      "ui-gdpr-components": "static-1.7159",
      "ui-images": "static-2.745",
      "ui-memberships-settings-lib": "static-1.21417",
      "ui-product-approvals": "static-1.19566",
      "ui-shepherd-react": "static-3.6456",
      "ui-shepherd-tracker": "static-1.3837",
      "ui-suspension-banners-lib": "static-1.3379",
      "ui-tool-access": "static-1.6570",
      "ui-universal-auth": "static-1.5159",
      "UIComponents": "static-3.5319",
      "underscore": "static-1.8",
      "upgrade-management-service-types": "static-1.1823",
      "urlinator": "static-1.1628",
      "usage-tracker": "static-1.4201",
      "usage-tracker-container": "static-1.4191",
      "usage-tracker-core": "static-1.4051",
      "user-context": "static-1.2079",
      "video-data-lib": "static-1.23834",
      "video-embed": "static-1.24677",
      "videoconference-integration-components": "static-1.2818",
      "web-interactives-components": "static-1.8790",
      "web-interactives-data": "static-1.8790",
      "webpack-env": "static-1.41",
      "xray-recommendation-lib": "static-1.3612",
      "classnames": "static-2.10",
      "fuzzy": "static-1.8",
      "growth-onboarding-confetti": "static-1.168",
      "immutable": "static-2.19",
      "nav-meta": "static-1.22086",
      "react-dom": "static-7.85",
      "redux": "static-4.16",
      "styled-components": "static-2.40",
      "wootric-nps": "static-1.4023",
      "codemirror": "static-5.25",
      "react-codemirror": "static-1.7473",
      "hs-promise-rejection-tracking": "static-1.2371",
      "raven-hubspot": "static-1.2784",
      "hoist-non-react-statics": "static-3.9",
      "bend-plugin-trellis-migration": "static-1.509",
      "moment": "static-3.26",
      "automation-platform-service-types": "static-1.1181",
      "ts-schema": "static-1.1498",
      "framework-listing-lib": "static-2.15628",
      "rxjs": "static-5.10",
      "NamespacedHubStyle": "static-2.6756",
      "flux-actions": "static-1.239",
      "native-integrations-shared-strings": "static-1.4760",
      "redux-observable": "static-1.11",
      "growth-onboarding-reliability": "static-1.2096",
      "commerce-billing-lib": "static-1.9421",
      "commerce-shared-components-lib": "static-1.10465",
      "crm_components": "static-3.80548",
      "crm_data": "static-4.80548",
      "crm_schema": "static-3.80583",
      "customer-data-filters-ui": "static-1.28013",
      "customer-data-table": "static-1.18800",
      "dispatcher": "static-1.100",
      "framework-data-table": "static-1.10262",
      "general-store": "static-6.20",
      "property-description-translator": "static-1.1316",
      "redux-toolkit": "static-1.7",
      "mobile-manifest-mixins": "static-1.261",
      "AudienceTargetingUIComponents": "static-2.12165",
      "conversations-skeleton-state": "static-1.4359",
      "crm_universal": "static-1.18578",
      "customer-data-content": "static-1.21365",
      "reporting-data": "static-1.55978",
      "subscriptions-iframe-lib": "static-1.16227",
      "usage-tracker-session-replay": "static-1.1887",
      "amplitude-session-replay-browser": "static-1.6",
      "customer-data-associations": "static-1.4225",
      "react-table": "static-1.15",
      "invoices-ui-lib": "static-1.43461",
      "customer-data-email": "static-1.13242",
      "SalesContentIndexUI": "static-3.4279",
      "sequences-lib": "static-1.2988",
      "draft-js": "static-5.37",
      "sequences-client-types-lib": "static-1.2844",
      "payments-enrollment-embed-lib": "static-1.12149",
      "FormUrlGenerator": "static-2.1837",
      "commerce-tools-ui-lib": "static-1.1860",
      "ecosystem-marketplace-core": "static-1.13704",
      "growth-onboarding-feedback-components": "static-1.3403",
      "stripe-embedded-components": "static-1.2662",
      "stripe-connect-js": "static-1.24",
      "customer-data-rte": "static-1.24696",
      "cv-backend-services": "static-1.708",
      "hubspot-prosemirror": "static-1.8650",
      "prosemirror": "static-1.13",
      "qrcode-generator": "static-1.33",
      "customer-data-filters": "static-1.41825",
      "customer-data-views-management": "static-1.17968",
      "framework-listing-lib-shared-msw-handlers": "static-1.4363",
      "draft-content-plugins": "static-1.20036",
      "draft-convert": "static-2.31",
      "draft-extend": "static-2.46",
      "draft-plugins": "static-2.16547",
      "framework-data-schema-resolvers-shared-msw-handlers": "static-1.4557",
      "immutable-less": "static-1.1646",
      "marketplace-ui-apps-core": "static-1.7758",
      "marketplace-ui-assets-core": "static-1.9164",
      "marketplace-ui-common": "static-1.9220",
      "marketplace-ui-reviews": "static-1.9182",
      "marketplace-ui-solutions-core": "static-1.9195",
      "signup-constants": "static-1.12702",
      "signup-ui-url-generator": "static-2.4916",
      "marketplace-ui-client-types": "static-1.6624",
      "trellis-ecosystem": "static-1.2058",
      "developer-experience-shared-components": "static-1.6677",
      "ui-extensibility-client-types": "static-1.1487",
      "knowledge-content-types": "static-1.6415",
      "reporting-client-types": "static-1.850",
      "calling-internal-common": "static-1.27644",
      "crm-export-dialog": "static-1.2768",
      "crm-index-associations-lib": "static-1.3072",
      "ui-addon-teams": "static-1.17304",
      "customer-data-filters-ui-msw-helpers": "static-1.10443",
      "lodash": "static-4.7",
      "apps-service-types": "static-1.1675",
      "oauth-service-types": "static-1.1451",
      "deep-diff": "static-1.5",
      "calling-error-reporting": "static-1.27649",
      "calling-usage-tracker": "static-1.3476"
    },
    "depPathPrefixes": {
      "page-editor-ui": "/page-editor-ui/static-1.25762",
      "ai-components-ui-library": "/ai-components-ui-library/static-1.2725",
      "apollo-dlb": "/apollo-dlb/static-3.41",
      "apollo-link-hub-http": "/apollo-link-hub-http/static-2.1728",
      "apollo-stack-hubspot": "/apollo-stack-hubspot/static-3.43",
      "atom": "/atom/static-1.2435",
      "autolinker": "/autolinker/static-3.11",
      "automation-applications-service-types": "/automation-applications-service-types/static-1.761",
      "automation-ui-source-apps": "/automation-ui-source-apps/static-1.9544",
      "automation-ui-types": "/automation-ui-types/static-1.10462",
      "backbone": "/backbone/static-1.6",
      "blueimp-md5": "/blueimp-md5/static-1.8",
      "browser-eslint": "/browser-eslint/static-2.9",
      "browserslist-config-hubspot": "/browserslist-config-hubspot/static-1.133",
      "campaigns-lib": "/campaigns-lib/static-1.17889",
      "chatspot-client-types": "/chatspot-client-types/static-1.5766",
      "chatspot-core": "/chatspot-core/static-1.9786",
      "chatspot-widget-iframe": "/chatspot-widget-iframe/static-1.9784",
      "cms-field-types": "/cms-field-types/static-1.1790",
      "codemirror-dlb": "/codemirror-dlb/static-1.46958",
      "collaboration-sidebar": "/collaboration-sidebar/static-1.45581",
      "commerce-analytics-service-client": "/commerce-analytics-service-client/static-1.1053",
      "conditional-properties-service-client": "/conditional-properties-service-client/static-1.1554",
      "content-assistance-lib": "/content-assistance-lib/static-1.3895",
      "content-embed-lib": "/content-embed-lib/static-1.3581",
      "content-icons-ui": "/content-icons-ui/static-1.1788",
      "content-management-data-lib": "/content-management-data-lib/static-1.2035",
      "ContentComponents": "/ContentComponents/static-2.60599",
      "ContentData": "/ContentData/static-1.57717",
      "ContentEditorUI": "/ContentEditorUI/static-2.65408",
      "ContentUtils": "/ContentUtils/static-1.57639",
      "copilot-plugins": "/copilot-plugins/static-1.1517",
      "copilot-toolkit": "/copilot-toolkit/static-1.1543",
      "crm-links": "/crm-links/static-1.2892",
      "crm-message-bus": "/crm-message-bus/static-1.29790",
      "crm-pipelines-api-client-types": "/crm-pipelines-api-client-types/static-1.1391",
      "cropperjs": "/cropperjs/static-1.10",
      "csstype": "/csstype/static-1.8",
      "cssUtils": "/cssUtils/static-1.314",
      "cta-picker": "/cta-picker/static-1.26936",
      "customer-data-objects": "/customer-data-objects/static-1.4544",
      "customer-data-objects-ui-components": "/customer-data-objects-ui-components/static-1.9631",
      "customer-data-properties": "/customer-data-properties/static-1.39447",
      "customer-data-property-utils": "/customer-data-property-utils/static-1.4787",
      "customer-data-reference-ui-components": "/customer-data-reference-ui-components/static-1.9733",
      "customer-data-tracking": "/customer-data-tracking/static-1.3848",
      "customer-data-ui-utilities": "/customer-data-ui-utilities/static-1.6723",
      "data-fetching-client": "/data-fetching-client/static-1.5966",
      "data-token-picker": "/data-token-picker/static-1.10969",
      "data-token-views": "/data-token-views/static-1.10953",
      "diff-match-patch": "/diff-match-patch/static-1.7",
      "editor-components": "/editor-components/static-1.9031",
      "editor-iframe-lib": "/editor-iframe-lib/static-1.5191",
      "EmailComponents": "/EmailComponents/static-1.41992",
      "EmailData": "/EmailData/static-1.40665",
      "emoji-mart": "/emoji-mart/static-3.4",
      "emoji-regex": "/emoji-regex/static-1.7",
      "enviro": "/enviro/static-4.260",
      "error-messaging-js": "/error-messaging-js/static-1.2356",
      "ExportDialog": "/ExportDialog/static-6.6863",
      "external-options-client-types": "/external-options-client-types/static-1.1493",
      "fast-json-stable-stringify": "/fast-json-stable-stringify/static-1.4",
      "feature-store-service-types": "/feature-store-service-types/static-1.1832",
      "feedback-loader": "/feedback-loader/static-1.23206",
      "file-manager-components": "/file-manager-components/static-1.13104",
      "FileManagerCore": "/FileManagerCore/static-1.28605",
      "FileManagerImages": "/FileManagerImages/static-1.26255",
      "FileManagerLib": "/FileManagerLib/static-1.28748",
      "FireAlarmUi": "/FireAlarmUi/static-1.2273",
      "floating-ui": "/floating-ui/static-1.15",
      "forms-utils-lib": "/forms-utils-lib/static-1.7275",
      "FormsCommonComponents": "/FormsCommonComponents/static-1.75613",
      "foundations-assets": "/foundations-assets/static-1.1755",
      "foundations-components": "/foundations-components/static-1.3158",
      "foundations-theming": "/foundations-theming/static-1.858",
      "framer-motion": "/framer-motion/static-1.25",
      "framework-builder-read-service-client": "/framework-builder-read-service-client/static-1.1480",
      "framework-data-schema-quick-fetch": "/framework-data-schema-quick-fetch/static-1.3267",
      "framework-data-schema-resolvers": "/framework-data-schema-resolvers/static-1.3364",
      "frontend-preferences-client": "/frontend-preferences-client/static-1.4501",
      "getting-started-shared-tasks": "/getting-started-shared-tasks/static-1.5909",
      "graphql": "/graphql/static-1.31",
      "growth-data-modal": "/growth-data-modal/static-1.1948",
      "growth-payments-core": "/growth-payments-core/static-1.16330",
      "head-dlb": "/head-dlb/static-1.2040",
      "HeadJS": "/HeadJS/static-2.491",
      "history": "/history/static-4.15",
      "hs-lodash": "/hs-lodash/static-4.9",
      "hs-promise-utils": "/hs-promise-utils/static-1.2453",
      "hs-story-utils": "/hs-story-utils/static-1.5769",
      "hs-test-utils": "/hs-test-utils/static-1.4821",
      "hub-http": "/hub-http/static-1.2732",
      "hub-http-contrib": "/hub-http-contrib/static-1.533",
      "hub-http-janus": "/hub-http-janus/static-1.557",
      "hub-http-rxjs": "/hub-http-rxjs/static-1.523",
      "hub-http-shared-msw-handlers": "/hub-http-shared-msw-handlers/static-1.4471",
      "hubspot-apollo-client": "/hubspot-apollo-client/static-1.1732",
      "hubspot-dlb": "/hubspot-dlb/static-1.1772",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1402",
      "hubspotter-http": "/hubspotter-http/static-1.2068",
      "HubStyle": "/HubStyle/static-2.8679",
      "HubStyleTokens": "/HubStyleTokens/static-2.8369",
      "I18n": "/I18n/static-7.1250",
      "i18n-data": "/i18n-data/static-1.183",
      "icons": "/icons/static-2.582",
      "immer": "/immer/static-1.33",
      "inbound-db-meta-service-client": "/inbound-db-meta-service-client/static-1.1247",
      "inbound-db-properties-service-client": "/inbound-db-properties-service-client/static-1.1512",
      "InboxConnectUtils": "/InboxConnectUtils/static-4.7668",
      "InpageEditorUI": "/InpageEditorUI/static-1.73856",
      "integrations-error-boundary-lib": "/integrations-error-boundary-lib/static-1.4405",
      "integrations-lib": "/integrations-lib/static-1.6992",
      "interframe": "/interframe/static-3.1590",
      "jasmine": "/jasmine/static-4.1379",
      "jasmine-immutable": "/jasmine-immutable/static-1.328",
      "jasmine-runner": "/jasmine-runner/static-1.3280",
      "jquery": "/jquery/static-3.6",
      "laboratory-lib": "/laboratory-lib/static-3.3800",
      "layout-data-lib": "/layout-data-lib/static-1.5533",
      "layout-dnd-components": "/layout-dnd-components/static-1.8233",
      "layout-dnd-utils": "/layout-dnd-utils/static-1.7208",
      "marketing-contacts-lib": "/marketing-contacts-lib/static-1.5606",
      "marketing-email-service-types": "/marketing-email-service-types/static-1.815",
      "marketing-platform-lib": "/marketing-platform-lib/static-1.3483",
      "media-bridge-lib": "/media-bridge-lib/static-1.13622",
      "merchandise-lib": "/merchandise-lib/static-1.202",
      "messaging-editing-lib": "/messaging-editing-lib/static-1.33428",
      "messaging-types-lib": "/messaging-types-lib/static-1.33162",
      "metrics-js": "/metrics-js/static-1.6084",
      "moment-timezone": "/moment-timezone/static-5.17",
      "msw": "/msw/static-1.39",
      "object-builder-ui-client": "/object-builder-ui-client/static-1.34439",
      "onboarding-tours": "/onboarding-tours/static-1.10732",
      "onboarding-tours-client": "/onboarding-tours-client/static-1.1555",
      "onboarding-utils": "/onboarding-utils/static-1.2192",
      "outpost": "/outpost/static-1.1477",
      "PatternValidationJS": "/PatternValidationJS/static-1.437",
      "payment-link-components": "/payment-link-components/static-1.12569",
      "payments-embed-points-lib": "/payments-embed-points-lib/static-1.5295",
      "payments-post-enroll-local-storage-lib": "/payments-post-enroll-local-storage-lib/static-1.2014",
      "permission-components": "/permission-components/static-1.2310",
      "persist-promise": "/persist-promise/static-1.808",
      "pictos": "/pictos/static-7.147",
      "pipeline-validation-client-types": "/pipeline-validation-client-types/static-1.1455",
      "PortalIdParser": "/PortalIdParser/static-2.239",
      "products-ui-components": "/products-ui-components/static-1.30484",
      "property-translator": "/property-translator/static-1.2843",
      "quartz": "/quartz/static-1.3481",
      "quartz-core": "/quartz-core/static-1.2700",
      "quartz-core-utils": "/quartz-core-utils/static-1.348",
      "quick-fetch": "/quick-fetch/static-1.1586",
      "raven": "/raven/static-3.2477",
      "react": "/react/static-7.133",
      "react-aria": "/react-aria/static-1.7",
      "react-beautiful-dnd": "/react-beautiful-dnd/static-1.13",
      "react-colorpicker": "/react-colorpicker/static-2.7161",
      "react-dnd": "/react-dnd/static-4.38",
      "react-dnd-compat": "/react-dnd-compat/static-1.1136",
      "react-dnd-html5-backend": "/react-dnd-html5-backend/static-4.5",
      "react-dnd-ipeui-backend": "/react-dnd-ipeui-backend/static-1.429",
      "react-flip-move": "/react-flip-move/static-1.9",
      "react-immutable-proptypes": "/react-immutable-proptypes/static-2.8",
      "react-immutable-render-mixin": "/react-immutable-render-mixin/static-1.9",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "react-redux": "/react-redux/static-7.16",
      "react-rhumb": "/react-rhumb/static-1.13149",
      "react-router-dom": "/react-router-dom/static-5.26",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-tinymce": "/react-tinymce/static-1.45132",
      "react-transition-group": "/react-transition-group/static-1.7",
      "react-utils": "/react-utils/static-2.2744",
      "react-virtualized": "/react-virtualized/static-2.62",
      "redux-mock-store": "/redux-mock-store/static-1.8",
      "redux-request-state": "/redux-request-state/static-1.7383",
      "redux-thunk": "/redux-thunk/static-2.17",
      "redux-undo": "/redux-undo/static-1.10",
      "ReduxMessenger": "/ReduxMessenger/static-2.7605",
      "reference-resolvers": "/reference-resolvers/static-1.5247",
      "reference-resolvers-lite": "/reference-resolvers-lite/static-1.4814",
      "reselect": "/reselect/static-2.16",
      "rich-text-lib": "/rich-text-lib/static-1.14748",
      "rpc-client-utils": "/rpc-client-utils/static-1.1643",
      "SafeStorage": "/SafeStorage/static-1.1543",
      "sales-checkout-service-client": "/sales-checkout-service-client/static-1.1065",
      "salesImages": "/salesImages/static-1.485",
      "sanitize": "/sanitize/static-1.6",
      "sanitize-text": "/sanitize-text/static-1.5188",
      "sassPrefix": "/sassPrefix/static-1.116",
      "seamless-immutable": "/seamless-immutable/static-7.6",
      "self-service-api": "/self-service-api/static-1.7082",
      "settings-ui-lib": "/settings-ui-lib/static-1.9356",
      "SharedI18nStrings": "/SharedI18nStrings/static-1.159",
      "SharedLegalStrings": "/SharedLegalStrings/static-1.1182",
      "short-messages-app-service-client": "/short-messages-app-service-client/static-1.1320",
      "simple-menus-core": "/simple-menus-core/static-1.10844",
      "simple-menus-lib": "/simple-menus-lib/static-1.12362",
      "sinon": "/sinon/static-1.9",
      "smart-content-core": "/smart-content-core/static-1.31807",
      "smart-content-lib": "/smart-content-lib/static-1.32462",
      "social-composer-lib": "/social-composer-lib/static-1.48835",
      "structured-content-lib": "/structured-content-lib/static-1.14377",
      "StyleGuideUI": "/StyleGuideUI/static-3.421",
      "superstore": "/superstore/static-1.1768",
      "tanstack-table": "/tanstack-table/static-1.10",
      "testing-library": "/testing-library/static-1.109",
      "theme-previewer-lib": "/theme-previewer-lib/static-1.41276",
      "timezone-utils": "/timezone-utils/static-2.2448",
      "tinymce": "/tinymce/static-6.5",
      "tinymce-config": "/tinymce-config/static-2.44259",
      "tinymce-copilot": "/tinymce-copilot/static-1.6327",
      "tinymce-data": "/tinymce-data/static-1.30341",
      "tinymce-plugins": "/tinymce-plugins/static-1.47372",
      "tinymce-themes": "/tinymce-themes/static-1.46226",
      "transmute": "/transmute/static-2.29",
      "ui-active-theme-content-creator-lib": "/ui-active-theme-content-creator-lib/static-1.25629",
      "ui-addon-avatars": "/ui-addon-avatars/static-2.6506",
      "ui-addon-draggable": "/ui-addon-draggable/static-1.4342",
      "ui-addon-emoji-picker": "/ui-addon-emoji-picker/static-1.6871",
      "ui-addon-form-validation": "/ui-addon-form-validation/static-1.7961",
      "ui-addon-i18n": "/ui-addon-i18n/static-1.7780",
      "ui-addon-iframeable": "/ui-addon-iframeable/static-1.6046",
      "ui-addon-integrations-directory-panel": "/ui-addon-integrations-directory-panel/static-2.4864",
      "ui-addon-opt": "/ui-addon-opt/static-4.5692",
      "ui-addon-react-router-dom": "/ui-addon-react-router-dom/static-1.6557",
      "ui-addon-sales-email": "/ui-addon-sales-email/static-1.2243",
      "ui-addon-upgrades": "/ui-addon-upgrades/static-9.13326",
      "ui-addon-video-player": "/ui-addon-video-player/static-1.5817",
      "ui-asset-management-lib": "/ui-asset-management-lib/static-1.6445",
      "ui-business-units-lib": "/ui-business-units-lib/static-1.5356",
      "ui-coaching-tips": "/ui-coaching-tips/static-1.15859",
      "ui-components-test-utils": "/ui-components-test-utils/static-1.2510",
      "ui-fonts": "/ui-fonts/static-1.326",
      "ui-gdpr-components": "/ui-gdpr-components/static-1.7159",
      "ui-images": "/ui-images/static-2.745",
      "ui-memberships-settings-lib": "/ui-memberships-settings-lib/static-1.21417",
      "ui-product-approvals": "/ui-product-approvals/static-1.19566",
      "ui-shepherd-react": "/ui-shepherd-react/static-3.6456",
      "ui-shepherd-tracker": "/ui-shepherd-tracker/static-1.3837",
      "ui-suspension-banners-lib": "/ui-suspension-banners-lib/static-1.3379",
      "ui-tool-access": "/ui-tool-access/static-1.6570",
      "ui-universal-auth": "/ui-universal-auth/static-1.5159",
      "UIComponents": "/UIComponents/static-3.5319",
      "underscore": "/underscore/static-1.8",
      "upgrade-management-service-types": "/upgrade-management-service-types/static-1.1823",
      "urlinator": "/urlinator/static-1.1628",
      "usage-tracker": "/usage-tracker/static-1.4201",
      "usage-tracker-container": "/usage-tracker-container/static-1.4191",
      "usage-tracker-core": "/usage-tracker-core/static-1.4051",
      "user-context": "/user-context/static-1.2079",
      "video-data-lib": "/video-data-lib/static-1.23834",
      "video-embed": "/video-embed/static-1.24677",
      "videoconference-integration-components": "/videoconference-integration-components/static-1.2818",
      "web-interactives-components": "/web-interactives-components/static-1.8790",
      "web-interactives-data": "/web-interactives-data/static-1.8790",
      "webpack-env": "/webpack-env/static-1.41",
      "xray-recommendation-lib": "/xray-recommendation-lib/static-1.3612",
      "classnames": "/classnames/static-2.10",
      "fuzzy": "/fuzzy/static-1.8",
      "growth-onboarding-confetti": "/growth-onboarding-confetti/static-1.168",
      "immutable": "/immutable/static-2.19",
      "nav-meta": "/nav-meta/static-1.22086",
      "react-dom": "/react-dom/static-7.85",
      "redux": "/redux/static-4.16",
      "styled-components": "/styled-components/static-2.40",
      "wootric-nps": "/wootric-nps/static-1.4023",
      "codemirror": "/codemirror/static-5.25",
      "react-codemirror": "/react-codemirror/static-1.7473",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.2371",
      "raven-hubspot": "/raven-hubspot/static-1.2784",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "bend-plugin-trellis-migration": "/bend-plugin-trellis-migration/static-1.509",
      "moment": "/moment/static-3.26",
      "automation-platform-service-types": "/automation-platform-service-types/static-1.1181",
      "ts-schema": "/ts-schema/static-1.1498",
      "framework-listing-lib": "/framework-listing-lib/static-2.15628",
      "rxjs": "/rxjs/static-5.10",
      "NamespacedHubStyle": "/NamespacedHubStyle/static-2.6756",
      "flux-actions": "/flux-actions/static-1.239",
      "native-integrations-shared-strings": "/native-integrations-shared-strings/static-1.4760",
      "redux-observable": "/redux-observable/static-1.11",
      "growth-onboarding-reliability": "/growth-onboarding-reliability/static-1.2096",
      "commerce-billing-lib": "/commerce-billing-lib/static-1.9421",
      "commerce-shared-components-lib": "/commerce-shared-components-lib/static-1.10465",
      "crm_components": "/crm_components/static-3.80548",
      "crm_data": "/crm_data/static-4.80548",
      "crm_schema": "/crm_schema/static-3.80583",
      "customer-data-filters-ui": "/customer-data-filters-ui/static-1.28013",
      "customer-data-table": "/customer-data-table/static-1.18800",
      "dispatcher": "/dispatcher/static-1.100",
      "framework-data-table": "/framework-data-table/static-1.10262",
      "general-store": "/general-store/static-6.20",
      "property-description-translator": "/property-description-translator/static-1.1316",
      "redux-toolkit": "/redux-toolkit/static-1.7",
      "mobile-manifest-mixins": "/mobile-manifest-mixins/static-1.261",
      "AudienceTargetingUIComponents": "/AudienceTargetingUIComponents/static-2.12165",
      "conversations-skeleton-state": "/conversations-skeleton-state/static-1.4359",
      "crm_universal": "/crm_universal/static-1.18578",
      "customer-data-content": "/customer-data-content/static-1.21365",
      "reporting-data": "/reporting-data/static-1.55978",
      "subscriptions-iframe-lib": "/subscriptions-iframe-lib/static-1.16227",
      "usage-tracker-session-replay": "/usage-tracker-session-replay/static-1.1887",
      "amplitude-session-replay-browser": "/amplitude-session-replay-browser/static-1.6",
      "customer-data-associations": "/customer-data-associations/static-1.4225",
      "react-table": "/react-table/static-1.15",
      "invoices-ui-lib": "/invoices-ui-lib/static-1.43461",
      "customer-data-email": "/customer-data-email/static-1.13242",
      "SalesContentIndexUI": "/SalesContentIndexUI/static-3.4279",
      "sequences-lib": "/sequences-lib/static-1.2988",
      "draft-js": "/draft-js/static-5.37",
      "sequences-client-types-lib": "/sequences-client-types-lib/static-1.2844",
      "payments-enrollment-embed-lib": "/payments-enrollment-embed-lib/static-1.12149",
      "FormUrlGenerator": "/FormUrlGenerator/static-2.1837",
      "commerce-tools-ui-lib": "/commerce-tools-ui-lib/static-1.1860",
      "ecosystem-marketplace-core": "/ecosystem-marketplace-core/static-1.13704",
      "growth-onboarding-feedback-components": "/growth-onboarding-feedback-components/static-1.3403",
      "stripe-embedded-components": "/stripe-embedded-components/static-1.2662",
      "stripe-connect-js": "/stripe-connect-js/static-1.24",
      "customer-data-rte": "/customer-data-rte/static-1.24696",
      "cv-backend-services": "/cv-backend-services/static-1.708",
      "hubspot-prosemirror": "/hubspot-prosemirror/static-1.8650",
      "prosemirror": "/prosemirror/static-1.13",
      "qrcode-generator": "/qrcode-generator/static-1.33",
      "customer-data-filters": "/customer-data-filters/static-1.41825",
      "customer-data-views-management": "/customer-data-views-management/static-1.17968",
      "framework-listing-lib-shared-msw-handlers": "/framework-listing-lib-shared-msw-handlers/static-1.4363",
      "draft-content-plugins": "/draft-content-plugins/static-1.20036",
      "draft-convert": "/draft-convert/static-2.31",
      "draft-extend": "/draft-extend/static-2.46",
      "draft-plugins": "/draft-plugins/static-2.16547",
      "framework-data-schema-resolvers-shared-msw-handlers": "/framework-data-schema-resolvers-shared-msw-handlers/static-1.4557",
      "immutable-less": "/immutable-less/static-1.1646",
      "marketplace-ui-apps-core": "/marketplace-ui-apps-core/static-1.7758",
      "marketplace-ui-assets-core": "/marketplace-ui-assets-core/static-1.9164",
      "marketplace-ui-common": "/marketplace-ui-common/static-1.9220",
      "marketplace-ui-reviews": "/marketplace-ui-reviews/static-1.9182",
      "marketplace-ui-solutions-core": "/marketplace-ui-solutions-core/static-1.9195",
      "signup-constants": "/signup-constants/static-1.12702",
      "signup-ui-url-generator": "/signup-ui-url-generator/static-2.4916",
      "marketplace-ui-client-types": "/marketplace-ui-client-types/static-1.6624",
      "trellis-ecosystem": "/trellis-ecosystem/static-1.2058",
      "developer-experience-shared-components": "/developer-experience-shared-components/static-1.6677",
      "ui-extensibility-client-types": "/ui-extensibility-client-types/static-1.1487",
      "knowledge-content-types": "/knowledge-content-types/static-1.6415",
      "reporting-client-types": "/reporting-client-types/static-1.850",
      "calling-internal-common": "/calling-internal-common/static-1.27644",
      "crm-export-dialog": "/crm-export-dialog/static-1.2768",
      "crm-index-associations-lib": "/crm-index-associations-lib/static-1.3072",
      "ui-addon-teams": "/ui-addon-teams/static-1.17304",
      "customer-data-filters-ui-msw-helpers": "/customer-data-filters-ui-msw-helpers/static-1.10443",
      "lodash": "/lodash/static-4.7",
      "apps-service-types": "/apps-service-types/static-1.1675",
      "oauth-service-types": "/oauth-service-types/static-1.1451",
      "deep-diff": "/deep-diff/static-1.5",
      "calling-error-reporting": "/calling-error-reporting/static-1.27649",
      "calling-usage-tracker": "/calling-usage-tracker/static-1.3476"
    },
    "project": "page-editor-ui",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};